/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";

:host ::ng-deep {
    .p-inputtext {
        padding: 0.5rem 0.75rem !important;
    }

    .p-button {
        height: 35px;
        border-radius: 6px;
        padding: 0.75rem 1.25rem;
        font-size: 1rem;
    }

    .p-button-label {
        font-weight: 400;
    }

    .tabMenu {
        .p-menuitem-link {
            text-decoration: none !important;
            color: #6b7280 !important;
            font-size: 0.85rem !important;
        }

        .p-tabmenuitem.p-highlight .p-menuitem-link {
            color: #3B82F6 !important;
            font-size: 0.85rem !important;
        }
    }

    .searchField {
        .p-inputtext {
            padding-left: 2.5rem !important;/* Adjust the value as needed */
        }

        .p-inputtext::placeholder { /* Target the placeholder directly */
            transition: transform 0.3s ease-in-out; /* Add transition */
        }
    }
}

.socialLoginBtn {
    width: 100%;
    min-height: 50px;

    button {
        height: 100%;
        width: 100%;
    }

    img {
        height: 30px;
        width: 30px;
    }
}


.navBar {
    min-height: 60px;
    padding: 0 2rem;

    .companyHeader {
        font-size: clamp(16px, 3vw, 22px);
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-style: normal;
    }

}

.whiteNav {
    background: white !important;
}

@media screen and (max-width:768px){
    .navBar {
        padding: 0 1rem;
    }    
}